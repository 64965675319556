import * as THREE from "/@fs/app/node_modules/.vite/deps/three.js?v=2d8e3bc4"
import GUI from "/@fs/app/node_modules/.vite/deps/lil-gui.js?v=2d8e3bc4"
import * as CANNON from "/@fs/app/node_modules/.vite/deps/cannon-es.js?v=2d8e3bc4"
import { FontLoader } from "/@fs/app/node_modules/.vite/deps/three_examples_jsm_loaders_FontLoader__js.js?v=2d8e3bc4"
import { TextGeometry } from "/@fs/app/node_modules/.vite/deps/three_examples_jsm_geometries_TextGeometry__js.js?v=2d8e3bc4"

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()

const ballTexture = textureLoader.load('/textures/matcap-5.png')
ballTexture.colorSpace = THREE.SRGBColorSpace
const pinsTexture = textureLoader.load('/textures/matcap-1.png')
pinsTexture.colorSpace = THREE.SRGBColorSpace

/**
 * Fonts
 */
const fontLoader = new FontLoader
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) => {
        const noTextGeometry = new TextGeometry(
            'NO',
            {
                font,
                size: 0.25,
                depth: 0.05,
                curveSegments: 5,
            }
        )
        noTextGeometry.center()
        const yesTextGeometry = new TextGeometry(
            'YES',
            {
                font,
                size: 0.25,
                depth: 0.05,
                curveSegments: 5,
            }
        )
        yesTextGeometry.center()
        const maybeTextGeometry = new TextGeometry(
            'MAYBE',
            {
                font,
                size: 0.25,
                depth: 0.05,
                curveSegments: 5,
            }
        )
        maybeTextGeometry.center()

        const textMaterial = new THREE.MeshBasicMaterial(
        { 
            color: '#eeeeee'
        })

        const noText = new THREE.Mesh(noTextGeometry, textMaterial)
        const yesText = new THREE.Mesh(yesTextGeometry, textMaterial)
        const maybeText = new THREE.Mesh(maybeTextGeometry, textMaterial)

        noText.position.set(-1.35, -7.25, 0.5)
        yesText.position.set(0, -7.25, 0.5)
        maybeText.position.set(1.35, -7.25, 0.5)

        scene.add(noText, yesText, maybeText)
    }
)

/**
 * Sounds
 */
const hitSound1 = new Audio('/sounds/90s-game-ui-2-185095.mp3')
const hitSound2 = new Audio('/sounds/90s-game-ui-6-185099.mp3')
const hitSound3 = new Audio('/sounds/90s-game-ui-7-185100.mp3')
const spawnSound = new Audio('/sounds/cartoon-yoink-1-183915.mp3')
const delay = 50
let timeSinceLastHit = 0

const playHitSound = (collision) => 
{
    const now = Date.now()
    const random = Math.random()
    const impactStrength = collision.contact.getImpactVelocityAlongNormal()

    if (now - timeSinceLastHit > delay && impactStrength > 1.5) {

        // choose a random sound to play
        if (random < 0.33) {
            hitSound1.volume = impactStrength <= 5 ? impactStrength/5 : 1
            hitSound1.currentTime = 0
            hitSound1.play()
        } else if (random < 0.66) {
            hitSound2.currentTime = 0
            hitSound2.play()
        } else {
            hitSound3.currentTime = 0
            hitSound3.play()
        }
        timeSinceLastHit = now
    }
}

const playSpawnSound = () => 
{
    spawnSound.currentTime = 0
    spawnSound.play()
}

/**
 * HTML Interface
 */
const hintQuestions = [
    "Should I have another round?",
    "Should I eat the last dougnut?",
    "Do my friends think I'm funny?",
    "Should I go to bed?",
    "Should I go to the gym?",
    "Should I get a cat?",
    "Is it time to quit my job?",
    "Will I ever find love?",
    "Should I buy a house?",
    "Should I get a tattoo?",
    "Does my crush like me?",
    "Is she mad at me?",
    "Should I move out?",
    "Should I go to the party?",
    "Should I go to work today?",
    "Does this dress make me look fat?",
    "Are my parents proud of me?",
    "Will I get fired if I make funny websites instead of working?",
    "Is browsing memes all day a good way to spend my time?",
    "Should I watch another episode?",
    "Is he thinking about me?",
    "Does my pet undertand what I'm saying?",
    "Will I make a million dollars?",
    "Is there such a thing as too much pizza?",
    "Should I get bangs?",
    "Am I going to get that promotion?",
    "Should I take a nap?",
    "Should I call my ex?",
]

const questionField = document.getElementById('fquestion')
const randomQuestion = hintQuestions[Math.floor(Math.random() * hintQuestions.length)]
questionField.placeholder = randomQuestion

const submitButton = document.getElementById('submit-button')
submitButton.addEventListener('click', () => 
{
    spawnBall()
    playSpawnSound()
})

const resetButton = document.getElementById('reset-button')
resetButton.addEventListener('click', () =>
{
    reset()
    window.scrollTo(0, 0)
})

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Physics
 */
const world = new CANNON.World()
world.broadphase = new CANNON.SAPBroadphase(world)
world.allowSleep = true
world.gravity.set(0, -9.82, 0)

/**
 * Objects
 */
// Materials
const defaultMaterial = new CANNON.Material('default')
const defaultContactMaterial = new CANNON.ContactMaterial(
    defaultMaterial, 
    defaultMaterial, 
    {
        friction: 0.3,
        restitution: 0.7
    }
)
world.addContactMaterial(defaultContactMaterial)
world.defaultContactMaterial = defaultContactMaterial

// Container
const backPlaneShape = new CANNON.Plane()
const backPlaneBody = new CANNON.Body(
{
    shape: backPlaneShape
})
backPlaneBody.position.set(0, 0, -0.25)
world.addBody(backPlaneBody)

const frontPlaneShape = new CANNON.Plane()
const frontPlaneBody = new CANNON.Body(
{
    shape: frontPlaneShape
})
frontPlaneBody.position.set(0, 0, 0.27)
frontPlaneBody.quaternion.setFromAxisAngle(new CANNON.Vec3(0, 1, 0), Math.PI)
world.addBody(frontPlaneBody)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 6
cameraGroup.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    // make the canvas transparent
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Scroll
 */
let scrollY = window.scrollY
window.addEventListener('scroll', () => {
    scrollY = window.scrollY
})

/**
 * Utils
 */
const objectsToUpdate = []

let ball = {}
const ballRadius = 0.25
const ballGeometry = new THREE.SphereGeometry(ballRadius, 20, 20)
const ballMaterial = new THREE.MeshMatcapMaterial({ matcap: ballTexture })

// spawn a ball in the scene
const spawnBall = () => {
    if (ball.mesh && ball.body) {
        // only one ball can exist at a time
        reset()
    }
    // Three.js Mesh
    const mesh = new THREE.Mesh(ballGeometry, ballMaterial) 
    const ballPosition = (Math.random() - 0.5) * 2
    mesh.position.set(ballPosition, 0, 0)
    scene.add(mesh)

    // Cannon body
    const shape = new CANNON.Sphere(ballRadius)
    const body = new CANNON.Body({
        mass: 1,
        position: new CANNON.Vec3(ballPosition, 0, 0),
        shape,  
    })
    body.addEventListener('collide', playHitSound)
    world.addBody(body)

    // Save in objects to update
    ball.mesh = mesh
    ball.body = body
}

// remove the ball from the scene
const reset = () => 
{
    if (ball.mesh && ball.body) {
        world.removeBody(ball.body)
        scene.remove(ball.mesh)

        ball = {}
    }
}

// pins
const pinMaterial = new THREE.MeshMatcapMaterial({ matcap: pinsTexture })
const pins = [
    {radius: 0.1, height: 0.25, rotation: 0},
    {radius: 0.1, height: 0.5, rotation: 0},
    {radius: 0.1, height: 0.75, rotation: 0},
    {radius: 0.1, height: 0.25, rotation: Math.PI * 0.5},
    {radius: 0.25, height: 0.25, rotation: Math.PI * 0.5},
    {radius: 0.4, height: 0.25, rotation: Math.PI * 0.5}
]

const collumns = 3
const rows = 4
const numberOfCells = collumns * rows
const cellSize = 1.5
const padding = cellSize * 0.5
const startCoordinate = { x: -cellSize - padding, y: -padding, z: 0 }

const placePins = () => 
{
    // in each cell spawn a random pin
    for (let i = 0; i < numberOfCells; i++) {
        // choose a pin to spawn at random
        const pinIndex = Math.floor(Math.random() * pins.length)
        const pinXPosition = startCoordinate.x + ((i % collumns) * cellSize) + padding
        const pinYPosition = startCoordinate.y - ((i % rows) * cellSize) - padding

        // create the ThreeJS Mesh
        const mesh = new THREE.Mesh(
            new THREE.CylinderGeometry(pins[pinIndex].radius, pins[pinIndex].radius, pins[pinIndex].height, 16),
            pinMaterial
        )
        mesh.position.set(pinXPosition, pinYPosition, 0)
        mesh.rotation.x = pins[pinIndex].rotation
        scene.add(mesh)

        // create CANNON body
        const shape = new CANNON.Cylinder(pins[pinIndex].radius, pins[pinIndex].radius, pins[pinIndex].height, 32)
        const body = new CANNON.Body({
            position: new CANNON.Vec3(pinXPosition, pinYPosition, 0),
            shape,
        })
        body.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), pins[pinIndex].rotation)
        world.addBody(body)

        objectsToUpdate.push({ mesh, body })
    }
}

placePins()

// Game board
const createBoard = () =>
{
    // place the borders on the left and right of the board
    // ThreeJS meshes
    const leftBorderCoordinates = 
    {
        x: startCoordinate.x - 0.25,
        y: startCoordinate.y - (cellSize * (rows * 0.5)),
        z: 0
    }
    const rightBorderCoordinates = 
    {
        x: startCoordinate.x + (cellSize * collumns) + 0.25,
        y: startCoordinate.y - (cellSize * (rows * 0.5)),
        z: 0
    }
    const leftRightBorderHeight = cellSize * rows
    const leftBorder = new THREE.Mesh(
        new THREE.BoxGeometry(0.1, leftRightBorderHeight, 0.25),
        pinMaterial
    )
    leftBorder.position.set(leftBorderCoordinates.x, leftBorderCoordinates.y, leftBorderCoordinates.z)
    scene.add(leftBorder)

    const rightBorder = new THREE.Mesh(
        new THREE.BoxGeometry(0.1, leftRightBorderHeight, 0.25),
        pinMaterial
    )
    rightBorder.position.set(rightBorderCoordinates.x, rightBorderCoordinates.y, rightBorderCoordinates.z)
    scene.add(rightBorder)

    // CANNON bodies
    const leftBorderShape = new CANNON.Box(
        new CANNON.Vec3(0.1, leftRightBorderHeight, 0.25)
    )
    const leftBorderBody = new CANNON.Body({
        position: new CANNON.Vec3(leftBorderCoordinates.x, leftBorderCoordinates.y, leftBorderCoordinates.z),
        shape: leftBorderShape
    })
    world.addBody(leftBorderBody)

    const rightBorderShape = new CANNON.Box(
        new CANNON.Vec3(0.1, leftRightBorderHeight, 0.25)
    )
    const rightBorderBody = new CANNON.Body({
        position: new CANNON.Vec3(rightBorderCoordinates.x, rightBorderCoordinates.y, rightBorderCoordinates.z),
        shape: rightBorderShape
    })
    world.addBody(rightBorderBody)

    // Add the boxes at the bottom of the board
    // ThreeJS meshes
    const bottomBorderCoordinates = 
    {
        x: 0,
        y: startCoordinate.y - (cellSize * rows) - 1,
        z: 0
    }
    const bottomBorderWidth = cellSize * collumns
    const boxHeight = 0.5
    const boxCoordinates = 
    {
        x: startCoordinate.x,
        y: startCoordinate.y - (cellSize * rows) - 0.5,
        z: 0
    }

    const bottomBorder = new THREE.Mesh(
        new THREE.BoxGeometry(bottomBorderWidth, 0.1, 0.25),
        pinMaterial
    )
    bottomBorder.position.set(bottomBorderCoordinates.x, bottomBorderCoordinates.y, bottomBorderCoordinates.z)
    scene.add(bottomBorder)

    for (let i = 0; i < 4; i++) 
    {
        const mesh = new THREE.Mesh(
            new THREE.BoxGeometry(0.1, boxHeight, 0.25),
            pinMaterial
        )
        mesh.position.set(boxCoordinates.x + (i * cellSize), boxCoordinates.y, boxCoordinates.z)
        scene.add(mesh)

        const shape = new CANNON.Box(
            new CANNON.Vec3(0.1, boxHeight - 0.25, 0.25)
        )
        const body = new CANNON.Body({
            position: new CANNON.Vec3(boxCoordinates.x + (i * cellSize), boxCoordinates.y, boxCoordinates.z),
            shape
        })
        world.addBody(body)
    }

    // CANNON bodies
    const bottomBorderShape = new CANNON.Box(
        new CANNON.Vec3(bottomBorderWidth, 0.1, 0.25)
    )
    const bottomBorderBody = new CANNON.Body({
        position: new CANNON.Vec3(bottomBorderCoordinates.x, bottomBorderCoordinates.y, bottomBorderCoordinates.z),
        shape: bottomBorderShape
    })
    world.addBody(bottomBorderBody)

    // generate and place the static pins on the board
    const numberOfStaticPins = rows * 2
    let currentRow = 0
    for (let i = 0; i < numberOfStaticPins; i++) {
        const pinXPosition = startCoordinate.x + (i % 2 + 1) * cellSize
        const pinYPosition = startCoordinate.y - (currentRow * cellSize)
        // ThreeJS mesh
        const mesh = new THREE.Mesh(
            new THREE.CylinderGeometry(0.1, 0.1, 0.25, 16),
            pinMaterial
        )
        mesh.position.set(pinXPosition, pinYPosition, 0)
        mesh.rotation.x = Math.PI * 0.5
        scene.add(mesh)

        // CANNON body
        const shape = new CANNON.Cylinder(0.1, 0.1, 0.25, 16)
        const body = new CANNON.Body({
            position: new CANNON.Vec3(pinXPosition, pinYPosition, 0),
            shape,
        })
        world.addBody(body)

        if (i % 2 == 1) 
        {
            currentRow += 1
        }
    }
}

createBoard()

/**
 * Raycaster
 */
const raycaster = new THREE.Raycaster()
const mouse = new THREE.Vector2()

window.addEventListener('click', (event) =>
{
    // Normalise the mouse position
    mouse.x = (event.clientX / sizes.width) * 2 - 1
    mouse.y = -(event.clientY / sizes.height) * 2 + 1

    // Update the picking ray with the camera and mouse position
    raycaster.setFromCamera(mouse, camera)

    // Calculate objects intersecting the picking ray
    const intersects = raycaster.intersectObjects(ball.mesh ? [ball.mesh] : [])

    for (const intersectObject of intersects) 
    {
        if (intersectObject.object === ball.mesh) 
        {
            // apply an impulse to the ball
            const impulse = 0.25
            const direction = new CANNON.Vec3(1, 0, 0)
            ball.body.applyImpulse(direction.scale(impulse), ball.body.position)
        }
    }
})

/**
 * Cursor
 */
const cursor = 
{
    x: 0,
    y: 0
}

window.addEventListener('mousemove', (event) =>
{
    // Retrieve the position of the mouse
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
})

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // update the physics world
    world.step(1 / 60, deltaTime, 3)

    if (ball.mesh && ball.body) {
        ball.mesh.position.copy(ball.body.position)
        ball.mesh.quaternion.copy(ball.body.quaternion)
    }

    // update the rotation of the pins
    let direction = 1
    for (const object of objectsToUpdate) {
        var quaternion = new CANNON.Quaternion()
        direction *= -1

        if (object.mesh.rotation.x == 0) 
        {
            quaternion.setFromAxisAngle(new THREE.Vector3(0, 0, 1), direction * elapsedTime)

            object.body.quaternion.copy(quaternion)
            object.mesh.quaternion.copy(object.body.quaternion)
        } else {
            quaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), direction * deltaTime)

            object.body.quaternion = object.body.quaternion.mult(quaternion)
            object.mesh.quaternion.copy(object.body.quaternion)
        }
    }

    // Animate the camera
    camera.position.y = - scrollY / sizes.height * 4

    // Parallax effect
    const parallaxX = cursor.x * 0.5
    const parallaxY = - cursor.y * 0.5
    cameraGroup.rotation.y += (cursor.x - cameraGroup.rotation.y) * deltaTime
    cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * deltaTime
    cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * deltaTime

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()